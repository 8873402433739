/* You can add global styles to this file, and also import other style files */
@import "../node_modules/uikit/src/scss/variables-theme.scss";
@import "../node_modules/uikit/src/scss/mixins-theme.scss";
@import "../node_modules/uikit/src/scss/uikit-theme.scss";

agm-map {
  height: 300px;
}

em,
a em {
  color: #1e87f0;
}

.uk-link,
a {
  color: #008cc8;
}

html {
  font-family: "D-DIN", sans-serif;
}
body {
  font-family: "D-DIN", sans-serif;
  background-color: #fbfbfb;
  color: #4f4f4f;
  font-size: 18px;
  line-height: 1.3;
  /*    font-family: 'Oswald', sans-serif;
        font-weight: lighter;*/
}

#navigationWrapper {
  background: white;
}

.appsubnav {
  background-color: #fbfbfb;

  .uk-select {
    border-radius: 7px;
  }
}

.uk-container {
  max-width: 1400px;
}
.uk-container-expand {
  max-width: none;
}

.full-width {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

.uk-button {
  border-radius: 7px;
  font-family: "D-DIN", sans-serif;
  /* font-size: 20px;
  padding: 10px 22px; */
  text-transform: none;
  /* line-height: 22px; */
}

.uk-button-primary {
  background-color: #008cc8;
}

#breadcrumb {
  position: absolute;
  margin-top: -120px;
  right: 14%;
  z-index: 2;
}
#breadcrumb.below {
  position: relative;
  margin-top: 0;
  right: auto;
}
#breadcrumb .breadcrumb {
  background: #fbfbfb;
}

@media screen and (max-width: 1600px) {
  #breadcrumb {
    margin-top: -100px;
  }
}
@media screen and (max-width: 1400px) {
  #breadcrumb {
    margin-top: -70px;
  }
}
@media screen and (max-width: 1150px) {
  #breadcrumb {
    position: relative;
    margin-top: 0;
    right: auto;
  }
}

/* TYPO3 defaults */
/*.csc-frame-default {
    margin-bottom: 30px;
  }*/
div.csc-textpic-imagewrap {
  /* max-width: 50%; */
}
div.csc-textpic-intext-left .csc-textpic-imagewrap,
div.csc-textpic-intext-left-nowrap .csc-textpic-imagewrap {
  margin-right: 30px;
  max-width: 50%;
}
div.csc-textpic-intext-right .csc-textpic-imagewrap,
div.csc-textpic-intext-right-nowrap .csc-textpic-imagewrap {
  margin-left: 30px;
  max-width: 50%;
}
div.csc-textpic .csc-textpic-imagewrap img {
  width: 100%;
  height: auto;
  object-fit: cover;
}
#content .ce-uploads li {
  overflow: hidden;
  margin: 0;
  padding: 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
#content .ce-uploads li:first-child {
  border-top: none;
}
#content .ce-uploads li a {
  text-decoration: none;
}
#content .ce-uploads li .ce-uploads-fileName a {
  font-family: "D-DIN";
  color: #008cc8;
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
}
.csc-frame-rulerBefore {
  border-top: 2px solid #7f7f7f;
  margin-top: 12px;
  padding-top: 12px;
}
.csc-frame-rulerAfter {
  border-bottom: 2px solid #7f7f7f;
  margin-bottom: 12px;
  padding-bottom: 12px;
}
#content > div:first-child {
  margin-top: 0;
}
/* defaults */
h1,
.h1,
h2,
.h2,
h3,
.h3 {
  margin-bottom: 0.6em;
  margin-top: 0;
}
.carousel-inner > .item {
  text-align: center;
}
.carousel-inner > .item > img,
.carousel-inner > .item > a > img {
  max-width: 100%;
  margin: 0 auto;
}
.carousel-indicators {
  bottom: 0;
  left: 0;
  margin-left: 0;
  padding: 0 15px;
  text-align: right;
  width: 100%;
}
.carousel-indicators li {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 0;
  border: none;
  height: 12px;
  width: 12px;
  margin: 0;
}
.carousel-indicators .active {
  background-color: #fff;
  border: none;
  height: 12px;
  width: 12px;
  margin: 0;
}
@media (max-width: 767px) {
  .carousel-indicators {
    bottom: initial;
  }
  .carousel-indicators li {
    background-color: rgba(0, 0, 0, 0.5);
  }
  .carousel-indicators li.active {
    background-color: #000;
  }
}
.carousel-control.left,
.carousel-control.right {
  width: 5%;
}
.carousel-info-box {
  background-color: rgba(203, 8, 86, 0.75);
  padding: 30px;
  position: absolute;
  left: 0;
  bottom: 1px;
  width: 450px;
  text-align: left;
}
.carousel-info-box .carousel-info-box-content {
  color: #fff;
  font-size: 20px;
  text-transform: uppercase;
  line-height: 160%;
}
.carousel-info-box .carousel-info-box-morelink {
  margin-top: 10px;
}
.carousel-info-box .carousel-info-box-morelink a {
  font-family: "Oswald", sans-serif;
  color: #fff;
  font-weight: lighter;
}
@media (max-width: 991px) {
  .carousel-info-box {
    padding: 10px;
    width: 300px;
  }
  .carousel-info-box .carousel-info-box-content {
    font-size: 14px;
    line-height: 160%;
  }
  .carousel-info-box .carousel-info-box-morelink {
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .carousel-info-box {
    position: relative;
    width: initial;
    height: 100px;
    margin-left: -15px;
    margin-right: -15px;
    bottom: 0;
    margin-bottom: 15px;
  }
}
.form-group {
  margin-bottom: 15px;
}
.form-control {
  /*border: none;
    border-radius: 0px;
    height: 30px;*/
  padding: 0 12px;
}
.form-control::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #999;
}
.form-control:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #999;
  opacity: 1;
}
.form-control::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #999;
  opacity: 1;
}
.form-control:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #999;
}
.form-control.number {
  text-align: right;
}
label {
  font-weight: normal;
}
.btn .caret {
  margin-left: 8px;
}
.caret {
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid;
}
@media (max-width: 991px) {
  .nopadding {
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin: 0 !important;
  }
}
#top {
  background-color: #fff;
  overflow: hidden;
  box-shadow: 0 0 10px #333;
  z-index: 9999;
  padding: 0 10px;
}
#top a {
  max-width: 50%;
}
#header {
  background-color: #c8c8c8;
  z-index: 5;
}
.wave-shape {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}
.wave-shape.bottom {
  top: 0;
  bottom: auto;
}

#homelink {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
}
#navigation {
  height: 50px;
  z-index: 15;
  padding: 10px 0;
}
#navigation > div {
  width: 100%;
}

.uk-navbar-dropdown {
  margin-top: 0;
  padding: 15px;
}
.uk-navbar-dropdown.uk-navbar-dropdown-width-2 {
  width: 300px;
}

.uk-offcanvas-bar,
.uk-offcanvas-bar .uk-nav-parent-icon > .uk-parent > a::after {
  content: "";
}
.uk-navbar-nav > li > a,
.uk-navbar-nav > li > a:visited,
.uk-navbar-dropdown-nav > li a,
.uk-navbar-dropdown-nav > li a:visited,
.uk-offcanvas-bar a,
.uk-offcanvas-bar a:visited,
.uk-offcanvas-bar .uk-nav-sub a,
.uk-offcanvas-bar .uk-nav-sub a:visited {
  font-family: "D-DIN", sans-serif;
  color: #008cc8;
  font-size: inherit;
  text-transform: none;
  font-size: 17px;
}

.uk-offcanvas-bar .uk-search-default .uk-search-input {
  border: 1px solid #e5e5e5;
  color: #00376e;
}

.uk-offcanvas-bar .uk-search .uk-search-icon,
.uk-offcanvas-bar .uk-search .uk-search-icon:hover {
  color: #00376e;
}

.uk-search .uk-search-icon.uk-search-icon-flip {
  top: 10px;
  bottom: unset;
}

.uk-navbar-nav > li > a:hover,
.uk-navbar-dropdown-nav > li a:hover,
.uk-offcanvas-bar a:hover,
.uk-offcanvas-bar .uk-nav-sub a:hover,
.uk-offcanvas-bar .uk-close:hover,
.uk-navbar-nav > li.menu-highlight > a,
.uk-navbar-nav > li.menu-highlight > a:hover,
.uk-navbar-nav > li.menu-highlight > a:visited {
  color: #f5aa41;
}
.uk-navbar-nav > li.menu-highlight > a {
  text-transform: uppercase;
}
.uk-offcanvas-bar .uk-close {
  color: #00376e;
}
.uk-navbar-toggle {
  color: #00376e;
}
.uk-navbar-toggle svg {
  max-width: inherit;
}
.uk-offcanvas-bar {
  background: #fff;
}
@media (max-width: 1230px) {
  #navigation {
    height: 50px;
  }
}
@media (max-width: 991px) {
  #navigation {
    height: 50px;
  }
}
@media (max-width: 767px) {
  #navigation {
    height: 50px;
  }
}

#navigation .container {
  width: 100%;
}
#navigation .container .navbar {
  z-index: 999;
  border: none;
}
#navigation .container .navbar .navbar-header {
  max-width: 330px;
  width: 30%;
}
@media (max-width: 1230px) {
  #navigation .container .navbar .navbar-header {
    max-width: 330px;
    width: 20%;
  }
}
@media (max-width: 1199px) {
  #navigation .container .navbar .navbar-header {
    max-width: 270px;
    width: 30%;
  }
}
@media (max-width: 991px) {
  #navigation .container .navbar .navbar-header {
    width: 130px;
    border: 1px solid red;
  }
}
#navigation .container .navbar .navbar-header .navbar-brand {
  padding: 0;
  height: 78px;
}
@media (max-width: 991px) {
  #navigation .container .navbar .navbar-header .navbar-brand {
    height: auto;
  }
}
#navigation .container .navbar .navbar-header .navbar-brand .img-responsive {
  width: 100%;
}
@media (max-width: 450px) {
  #navigation .container .navbar .navbar-header .navbar-brand .img-responsive {
    width: 130px;
  }
}
#navigation .container .navbar .navbar-header .navbar-toggle .button-bars {
  float: left;
  padding-top: 3px;
  padding-right: 3px;
}
#navigation
  .container
  .navbar
  .navbar-header
  .navbar-toggle
  .button-bars
  .icon-bar {
  background-color: #000;
}
@media (max-width: 450px) {
  #navigation .container .navbar .navbar-header .navbar-toggle .button-label {
    display: none;
  }
}
#navigation #metamenu > li > a {
  color: #f5aa41;
  text-transform: uppercase;
}

#navigation .container .navbar #mainmenu.nav {
  text-align: center;
  margin-left: 150px;
}
#navigation .container .navbar #mainmenu.nav > li > a:hover,
#navigation .container .navbar #mainmenu.nav > li > a:focus {
  color: #f5aa41;
  background-color: #f6f6f6;
}
#navigation .container .navbar #mainmenu.nav .open > a,
#navigation .container .navbar #mainmenu.nav .open > a:hover,
#navigation .container .navbar #mainmenu.nav .open > a:focus {
  background-color: #28c7ef;
  padding-bottom: 50px;
  margin-bottom: 0;
}
@media (max-width: 1199px) {
  #navigation .container .navbar #mainmenu.nav .open > a,
  #navigation .container .navbar #mainmenu.nav .open > a:hover,
  #navigation .container .navbar #mainmenu.nav .open > a:focus {
    padding-bottom: 25px;
  }
}
#navigation .container .navbar #mainmenu > li > a {
  font-family: "D-DIN";
  color: #008cc8;
  font-size: normal;
  font-style: normal;
  font-weight: normal;
  padding-top: 30px;
  padding-bottom: 30px;
  margin: 0px;
  color: #000;
  text-transform: uppercase;
  /*font-size: 16px;*/
  font-size: 20px;
  line-height: 20px;
  font-weight: 600;
  border: none;
  cursor: pointer;
}
@media (max-width: 1230px) {
  #navigation .container .navbar #mainmenu > li > a {
    padding-top: 15px;
    padding-bottom: 15px;
    margin-bottom: 10px;
    margin-top: 10px;
    /*font-size: 14px;*/
    font-size: 17px;
  }
}
@media (max-width: 1199px) {
  #navigation .container .navbar #mainmenu > li > a {
    padding-left: 10px;
    padding-right: 10px;
    /*font-size: 13px;*/
    font-size: 15px;
  }
}
#navigation .container .navbar #mainmenu > li > a img {
  vertical-align: baseline;
}
#navigation .container .navbar #mainmenu .dropdown-menu {
  background-color: rgba(40, 199, 239, 0.9);
  border: none;
  padding: 20px 10px;
}
#navigation .container .navbar #mainmenu .dropdown-menu > li > a {
  font-family: "Oswald", sans-serif;
  font-size: 16px;
  line-height: 20px;
  padding: 5px 20px;
  font-weight: normal;
}
@media (max-width: 1199px) {
  #navigation .container .navbar #mainmenu .dropdown-menu > li > a {
    font-size: 14px;
  }
}
#navigation .container .navbar #mainmenu .dropdown-menu > li > a:hover,
#navigation .container .navbar #mainmenu .dropdown-menu > li > a:focus {
  background-color: rgba(255, 255, 255, 0.4);
}
#navigation .container .navbar #mainmenu .dropdown-menu > .active > a,
#navigation .container .navbar #mainmenu .dropdown-menu > .active > a:hover,
#navigation .container .navbar #mainmenu .dropdown-menu > .active > a:focus {
  background-color: rgba(255, 255, 255, 0.4);
  color: #333;
}
#navigation .container .navbar #mainmenu form {
  margin: 0;
  padding: 0px;
}
#navigation .container .navbar #mainmenu form input {
  font-size: 19px;
  border-radius: 0;
  border: none;
  padding: 5px 15px 5px;
  height: auto;
}
#navigation .container .navbar #searchform.nav {
  text-align: center;
  margin-left: 0px;
}
#navigation .container .navbar #searchform.nav > li > a:hover,
#navigation .container .navbar #searchform.nav > li > a:focus {
  color: #f5aa41;
  background-color: #f6f6f6;
}
#navigation .container .navbar #searchform.nav .open > a,
#navigation .container .navbar #searchform.nav .open > a:hover,
#navigation .container .navbar #searchform.nav .open > a:focus {
  background-color: #28c7ef;
  padding-bottom: 38px;
  margin-bottom: 0;
}
@media (max-width: 1199px) {
  #navigation .container .navbar #searchform.nav .open > a,
  #navigation .container .navbar #searchform.nav .open > a:hover,
  #navigation .container .navbar #searchform.nav .open > a:focus {
    padding-bottom: 25px;
  }
}
#navigation .container .navbar #searchform > li > a {
  font-family: "D-DIN";
  color: #008cc8;
  font-size: normal;
  font-style: normal;
  font-weight: normal;
  padding-top: 30px;
  padding-bottom: 30px;
  margin: 0px;
  color: #000;
  text-transform: uppercase;
  /*font-size: 16px;*/
  font-size: 20px;
  line-height: 20px;
  font-weight: 600;
  border: none;
  cursor: pointer;
}
@media (max-width: 1230px) {
  #navigation .container .navbar #searchform > li > a {
    padding-top: 15px;
    padding-bottom: 15px;
    margin-bottom: 10px;
    margin-top: 10px;
    /*font-size: 14px;*/
    font-size: 17px;
  }
}
@media (max-width: 1199px) {
  #navigation .container .navbar #searchform > li > a {
    padding-left: 10px;
    padding-right: 10px;
    /*font-size: 13px;*/
    font-size: 15px;
  }
}
#navigation .container .navbar #searchform > li > a img {
  vertical-align: baseline;
}
#navigation .container .navbar #searchform .dropdown-menu {
  background-color: rgba(40, 199, 239, 0.9);
  border: none;
  padding: 20px 10px;
}
#navigation .container .navbar #searchform .dropdown-menu > li > a {
  font-family: "Oswald", sans-serif;
  font-size: 16px;
  line-height: 20px;
  padding: 5px 20px;
  font-weight: normal;
}
@media (max-width: 1199px) {
  #navigation .container .navbar #searchform .dropdown-menu > li > a {
    font-size: 14px;
  }
}
#navigation .container .navbar #searchform .dropdown-menu > li > a:hover,
#navigation .container .navbar #searchform .dropdown-menu > li > a:focus {
  background-color: rgba(255, 255, 255, 0.4);
}
#navigation .container .navbar #searchform .dropdown-menu > .active > a,
#navigation .container .navbar #searchform .dropdown-menu > .active > a:hover,
#navigation .container .navbar #searchform .dropdown-menu > .active > a:focus {
  background-color: rgba(255, 255, 255, 0.4);
  color: #333;
}
#navigation .container .navbar #searchform form {
  margin: 0;
  padding: 0px;
}
#navigation .container .navbar #searchform form input {
  font-size: 19px;
  border-radius: 0;
  border: none;
  padding: 5px 15px 5px;
  height: auto;
}
#navigation .container .navbar #languagemenu.nav > li > a:hover,
#navigation .container .navbar #languagemenu.nav > li > a:focus {
  text-decoration: none;
  color: #f5aa41;
  background-color: #f6f6f6;
}
#navigation .container .navbar #languagemenu.nav .open > a,
#navigation .container .navbar #languagemenu.nav .open > a:hover,
#navigation .container .navbar #languagemenu.nav .open > a:focus {
  background-color: #28c7ef;
  padding-bottom: 50px;
  margin-bottom: 0;
}
@media (max-width: 1199px) {
  #navigation .container .navbar #languagemenu.nav .open > a,
  #navigation .container .navbar #languagemenu.nav .open > a:hover,
  #navigation .container .navbar #languagemenu.nav .open > a:focus {
    padding-bottom: 25px;
  }
}
@media (max-width: 1230px) {
  #navigation .container .navbar #languagemenu > li > a {
    padding-top: 15px;
    padding-bottom: 15px;
    margin-bottom: 10px;
    margin-top: 10px;
    /*font-size: 14px;*/
    font-size: 17px;
  }
}
@media (max-width: 1199px) {
  #navigation .container .navbar #languagemenu > li > a {
    padding-left: 10px;
    padding-right: 10px;
    /*font-size: 13px;*/
    font-size: 15px;
  }
}
#navigation .container .navbar #languagemenu > li > a img {
  vertical-align: baseline;
}
#navigation .container .navbar #languagemenu .dropdown-menu {
  background-color: rgba(40, 199, 239, 0.9);
  border: none;
  padding: 20px 10px;
}
#navigation .container .navbar #languagemenu .dropdown-menu > li > a {
  font-family: "Oswald", sans-serif;
  font-size: 16px;
  line-height: 20px;
  padding: 5px 20px;
  font-weight: normal;
}
@media (max-width: 1199px) {
  #navigation .container .navbar #languagemenu .dropdown-menu > li > a {
    font-size: 14px;
  }
}
#navigation .container .navbar #languagemenu .dropdown-menu > li > a:hover,
#navigation .container .navbar #languagemenu .dropdown-menu > li > a:focus {
  background-color: rgba(255, 255, 255, 0.4);
}
#navigation .container .navbar #languagemenu .dropdown-menu > .active > a,
#navigation .container .navbar #languagemenu .dropdown-menu > .active > a:hover,
#navigation
  .container
  .navbar
  #languagemenu
  .dropdown-menu
  > .active
  > a:focus {
  background-color: rgba(255, 255, 255, 0.4);
  color: #333;
}
@media (max-width: 767px) {
  #navigation #navbar-collapse {
    background-color: #86dbf0;
  }
  #navigation #navbar-collapse #mainmenu > li > a {
    padding-top: 5px;
    padding-bottom: 5px;
    margin-bottom: 0;
    margin-top: 0;
  }
  #navigation #navbar-collapse #mainmenu > li > a img {
    vertical-align: middle;
  }
}

#mainmenu_oc .p-309 a,
#mainmenu_oc .p-311 a {
  color: #f5aa41;
  text-transform: uppercase;
}

#content-bg .uk-container {
  padding: 0 25px;
}

#main {
  margin-top: 40px;
}

#main .shaded-container {
  background-color: rgba(0, 159, 222, 0.17);
  padding: 14px;
}
.standardseite #main {
  margin-top: 20px;
}
#rightcol {
  color: #333;
  font-size: 16px;
  line-height: 1.35;
}
#rightcol .csc-frame.csc-frame-default {
  border-top: 2px solid #7f7f7f;
  padding-top: 18px;
  margin-top: 18px;
}
#rightcol .csc-frame.csc-frame-default:first-child {
  margin-top: 0;
}
#rightcol h2 {
  color: #7f7f7f;
  font-family: "Oswald", sans-serif;
  font-size: 22px;
  text-transform: uppercase;
  margin: 0 0 10px;
  font-weight: bold;
}
#rightcol h3 {
  background-color: #7f7f7f;
  color: #fff;
  font-family: "Oswald", sans-serif;
  font-size: 22px;
  font-weight: bold;
  text-transform: uppercase;
  margin: 0;
  padding: 5px 10px;
}
#rightcol h3 a {
  color: #fff;
  text-decoration: none;
}
#rightcol h3 a span {
  font-family: Verdana, sans-serif;
  font-size: 14px;
  margin-top: 3px;
  text-transform: none;
  font-weight: normal;
  line-height: 20px;
}
#rightcol ul {
  padding: 10px 0 0 20px;
}
#rightcol ul li {
  margin-bottom: 5px;
}
#rightcol ul a {
  color: #fff;
}
#rightcol .bodytext {
  padding: 10px 5px;
  text-align: left;
}
#rightcol a {
  color: #333;
}
#rightcol .projectlogos a {
  display: inline-block;
  max-width: 100%;
}
#rightcol .projectlogos a img {
  max-width: 100%;
}
.facebook {
  display: block;
  text-transform: uppercase;
}
.facebook:hover,
.facebook:active,
.facebook:focus {
  text-decoration: none;
}
.facebook img {
  vertical-align: bottom;
}
.facebook span {
  margin-left: 3px;
  font-size: 20px;
  color: #7f7f7f;
  font-weight: bold;
  font-family: "Oswald", sans-serif;
}
#preload {
  position: absolute;
  left: -9999999px;
  top: -9999999px;
  height: 0;
  width: 0;
  overflow: hidden;
}
.btn {
  margin-top: 20px;
}
.btn.btn-primary {
  background-color: #008cc8;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
  font-family: "D-DIN";
  color: #fff;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
}
.footer_144:before {
  content: "";
  width: 100%;
  height: 790px;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: -1;
  background-image: url("/assets/img/Vector_3_1.png");
  transform: rotate(-180deg);
  -webkit-background-size: cover;
  background-size: cover;
  padding: 50px 0px !important;
}
.footer_144 .newsletter,
.footer_144 .social-links,
.footer_144 .projects {
  background: #f2f2f2;
}
#footer-projects .headline {
  color: #7f7f7f;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: bold;
  margin-top: 15px;
  margin-bottom: 30px;
}

#footer-projects .projectlogos img {
  image-rendering: -webkit-optimize-contrast;
}

.projectlogos .uk-slider-items:not(.uk-grid) {
  margin: 0 60px;
}

#footer-container {
  background: #f2f2f2;
  position: relative;
  padding-top: 14vw;
}

#footer1 {
  background-color: #fbfbfb;
  padding: 50px 10px;
}
#footer1.footer1_144 {
  background-color: #f2f2f2;
}
#footer1 .headline {
  color: #7f7f7f;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: bold;
  margin-top: 15px;
  margin-bottom: 15px;
}
#footer1 .footerimage img {
  margin-top: 15px;
  margin-bottom: 15px;
}
#footer1 .footerimage.last {
  border-left: 2px solid #7f7f7f;
}
#footer2 {
  border-top: 0.5px solid #bdbdbd;
  background-color: #f2f2f2;
  padding-top: 30px;
  padding-bottom: 30px;
}
#footer2.footer2_144 {
  background-color: #f2f2f2;
}
#footer2 .container {
  width: 100%;
}
#footer2 .container h3 {
  line-height: 22px;
  /* identical to box height */
  letter-spacing: 0.05em;
  font-family: "D-DIN";
  color: #00376e;
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
}
#footer2 .container ul {
  margin: 0;
  padding: 0;
  overflow: hidden;
  list-style-type: none;
}
#footer2 .container ul li {
  margin-bottom: 10px;
}
#footer2 .container ul li a {
  font-family: "D-DIN";
  color: #008cc8;
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
}
#footer2 .container ul li a:hover {
  text-decoration: none;
}
#footer2 .container .sidebar-menu a {
  display: inline-block;
  margin-top: 45px;
  margin-right: 40px;
  font-family: "D-DIN";
  color: #008cc8;
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
}
#footer2 .container .sidebar-menu a:last-child {
  margin-right: 0;
}
#footer2 .container .footercol {
  margin-bottom: 30px;
}

.footernav-title {
  color: #00376e;
  font-size: 18px;
  line-height: 22px;
}
.footernav-main {
  list-style-type: none;
  padding-left: 0;
}
.footernav-main a,
.footernav-main a:visited,
.footernav a,
.footernav a:visited {
  color: #008cc8;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 10px;
}
.footernav-main a,
.footernav-main a:visited {
  display: block;
}
.footernav {
  padding-left: 0;
}
.footernav a {
  margin-right: 50px;
}
.footernav-accordion {
  border-top: 1px solid #bdbdbd;
}
.sidebar-menu {
  padding: 0;
}
.landingpage {
  max-width: 1400px;
  margin: auto;
}
.landingpage #content .csc-frame-default {
  margin-top: 0px;
}
#content p.bodytext {
  color: #333333;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  text-align: left;
}
div.bodytext,
span.bodytext,
#content p.bodytext,
#content p.MsoNormal,
#content .citko-li,
#content ul.csc-frame {
  font-family: Roboto;
  font-size: 16px;
  color: #4f4f4f;
}
#content p.bodytext a {
  color: #008cc8;
}
#content p.bodytext strong {
  font-family: Roboto;
  color: #00376e;
  font-weight: bold;
}

#content .csc-frame-default .csc-header h1 a {
  font-family: "D-DIN";
  color: #ffffff;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  text-decoration: none;
}

#content .csc-frame-default .csc-textpic-intext-left .csc-textpic-imagewrap {
  float: left;
  width: 48%;
}
#content
  .csc-frame-default
  .csc-textpic-intext-left
  .csc-textpic-imagewrap
  .csc-textpic-caption {
  font-family: "D-DIN";
  color: #4f4f4f;
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  text-align: center;
}
#content .csc-frame-default .csc-textpic-intext-left .csc-textpic-text {
  float: left;
  width: 48%;
  padding-top: 50px;
}
#content .csc-frame-default .csc-textpic-intext-left ul li a {
  font-family: "D-DIN";
  color: #008cc8;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  line-height: 42px;
  text-align: left;
  display: inline-block;
}

#content .csc-frame-default .csc-textpic-intext-right .csc-textpic-imagewrap {
  float: right;
  width: 48%;
}
#content
  .csc-frame-default
  .csc-textpic-intext-right
  .csc-textpic-imagewrap
  .csc-textpic-caption {
  font-family: "D-DIN";
  color: #4f4f4f;
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  text-align: center;
}
#content .csc-frame-default .csc-textpic-intext-right .csc-textpic-text {
  float: left;
  width: 48%;
  padding-top: 50px;
}
#content .csc-frame-default .csc-textpic-intext-right p.bodytext {
  font-family: "D-DIN";
  color: #333333;
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  line-height: 18px;
  text-align: left;
}
#content .csc-frame-default .csc-textpic-intext-right ul li a {
  font-family: "D-DIN";
  color: #008cc8;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  line-height: 42px;
  text-align: left;
  display: inline-block;
}
#content a {
  text-decoration: underline;
}
#content a.uk-button {
  text-decoration: none;
}
#content h1,
.header-container h1 {
  font-family: "D-DIN";
  color: #fff;
  font-size: 28px;
  font-style: normal;
  font-weight: 900;
  line-height: 34px;
}
.header-container:not(.no-image) h1,
.header-container:not(.no-image) p {
  text-shadow: 2px 2px 5px black;
}
#content h1 {
  color: #00376e;
}
.header-container p {
  font-family: Roboto;
  color: #fff;
  font-size: 18px;
  line-height: 20px;
}

#content h1.h1_magenta {
  /*font-family: 'dinboldwebfont';*/
  display: inline-block;
  /*background-color: #DC006A;*/
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  /*color: #FFFFFF;*/
  padding: 5px 10px;
  margin-top: 0;
}
#content h1.h1_blue {
  /*font-family: 'dinregularwebfont';*/
  display: inline-block;
  /*background-color: #00aadd;*/
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  /*color: #FFFFFF;*/
  padding: 5px 10px;
  margin-top: 0;
}
#content h2 {
  font-family: "D-DIN";
  color: #00376e;
  font-size: 36px;
  font-style: normal;
  font-weight: 900;
  line-height: 42px;
}
.listingalternativ #content h2 {
  color: #00376e;
  text-align: center;
}
#content .csc-subheader {
  font-family: Roboto;
  font-size: 18px;
  line-height: 24px;
  color: #00376e;
}
#content p {
  margin-bottom: 30px;
}
#content .carousel {
  margin-bottom: 20px;
}
#content .text-teaser {
  height: 191px;
  margin-bottom: 20px;
  padding: 20px;
}
@media (max-width: 991px) {
  #content .text-teaser {
    height: auto;
  }
}
#content .text-teaser h2 {
  color: #000;
  font-size: 20px;
  line-height: 130%;
  margin-top: 0;
  margin-bottom: 15px;
  text-transform: uppercase;
}
#content .text-teaser p {
  font-size: 14px;
  margin-bottom: 15px;
  height: 6em;
}
@media (max-width: 991px) {
  #content .text-teaser p {
    height: auto;
  }
}
#content .text-teaser .morelink {
  position: absolute;
  bottom: 20px;
  width: 100%;
  color: #000;
  text-decoration: underline;
  font-weight: bold;
}
#content .text-teaser .morelink a {
  text-decoration: none;
}
@media (max-width: 991px) {
  #content .text-teaser .morelink {
    position: relative;
    bottom: 0;
    right: 0;
    left: 0;
    margin-top: 15px;
  }
}
#content .text-teaser.white {
  background-color: #fff;
  border-top: 2px solid #7f7f7f;
  border-bottom: 2px solid #7f7f7f;
}
#content .text-teaser.blue {
  background-color: #00376e;
  color: #fff;
}
#content .text-teaser.blue h2 {
  color: #fff;
}
#content .text-teaser.blue .morelink {
  color: #fff;
}
#content .text-teaser.orange {
  background-color: #ffedd7;
  color: #4f4f4f;
}
#content .text-teaser.orange h2 {
  color: #4f4f4f;
}
#content .text-teaser.orange .morelink {
  color: #4f4f4f;
}
#content .text-teaser.green {
  background-color: #9bc8aa;
  color: #fff;
}
#content .text-teaser.green h2 {
  color: #fff;
}
#content .text-teaser.green .morelink {
  color: #fff;
}
#content .image-teaser .image-teaser-image img {
  width: 100%;
  height: auto;
}
#content .image-teaser .image-teaser-content {
  font-size: 19px;
  overflow: hidden;
}
#content .image-teaser .image-teaser-content h2 {
  color: #009fde;
  font-size: 28px;
  margin-top: 30px;
  margin-bottom: 15px;
}
#content .image-teaser .image-teaser-content .morelink {
  background-color: #7f7f7f;
  font-size: 14px;
  text-transform: uppercase;
  color: #fff;
  float: right;
  padding: 5px;
}
#content .imageonly-teaser {
  margin-bottom: 20px;
  overflow: hidden;
}
#content .imageonly-teaser .imageonly-teaser-image {
  background-color: #fff;
}
#content .imageonly-teaser .imageonly-teaser-image img {
  width: 100%;
  height: auto;
}
#content .imageonly-teaser .imageonly-teaser-text {
  background-color: rgba(255, 255, 255, 0.75);
  color: #555;
  text-shadow: 0 0 3px #fff;
  text-transform: uppercase;
  font-size: 20px;
  position: absolute;
  bottom: 30px;
  left: 15px;
  right: 15px;
  padding: 3px 10px;
}
#content .imageonly-teaser .imageonly-teaser-text a {
  text-decoration: none;
  color: #555;
}
#content .leistungen .category-list {
  list-style: none;
  padding: 0;
  margin-bottom: 30px;
}
#content .leistungen .category-list li {
  padding: 10px 20px;
  font-size: 20px;
  margin-bottom: 4px;
}
#content .leistungen .category-list li a {
  display: block;
  text-decoration: none;
  color: #fff;
}
#content .leistungen .category-list li a:hover {
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.33);
}
#content .leistungen .item {
  font-size: 15px;
  margin-bottom: 30px;
}
#content .leistungen .item .item-inner {
  max-width: 330px;
}
#content .leistungen .item a {
  display: block;
  text-decoration: none;
}
#content .leistungen .item:hover,
.category-selection .category-item:hover {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}
#content .leistungen .item a .item-inner .item-image {
  position: relative;
  border-bottom: 3px solid #fff;
}
#content .leistungen .item a .item-inner .item-image img {
  width: 100%;
  object-fit: cover;
}
#content .leistungen .item a .item-inner .item-new {
  background-color: #db6c18;
  position: absolute;
  height: 50px;
  width: 50px;
  border-radius: 10px;
  bottom: 10px;
  left: 20px;
  text-align: center;
  line-height: 50px;
  font-size: 22px;
  color: #fff;
}
#content .leistungen .item a .item-inner .item-info {
  height: 105px;
  padding: 15px;
  /*                        color: #FFFFFF;*/
}
#content .leistungen .item a .item-inner .item-info .title {
  font-family: "D-DIN";
  color: #008cc8;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  font-weight: bold;
  margin-bottom: 3px;
  line-height: 29px;
  letter-spacing: -0.02em;
}
#content .leistungen .item a .item-inner .item-info .city {
  font-family: "D-DIN";
  color: #008cc8;
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
}
#content .leistungen .uk-accordion {
  border: 0;
  border-radius: 0;
  box-shadow: none;
  margin-bottom: 3px;
  width: 100%;
  background: none;
}

#content .leistungen .uk-accordion > div + div {
  margin-top: 0;
}

#content .leistungen .uk-accordion .uk-accordion-title::before,
#content .faq-container .uk-accordion .uk-accordion-title::before {
  display: none;
}
#content .faq-container .uk-accordion .uk-accordion-title h4 {
  padding-right: 22px;
}
#content .faq-container .uk-accordion .uk-accordion-title h4:after {
  margin-right: -30px;
}
#content .leistungen .uk-accordion .uk-accordion-title h4:after,
#content .faq-container .uk-accordion .uk-accordion-title h4:after {
  margin-left: 12px;
  margin-top: 12px;
  font-weight: bold;
  content: "";
  border: solid #008cc8;
  border-width: 0 4px 4px 0;
  display: inline-block;
  padding: 6px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
#content .leistungen .uk-accordion {
  border: none;
  padding: 0;
}
#content .leistungen .uk-accordion .uk-accordion-content {
  margin: 0 0 20px;
}
#content .leistungen .uk-accordion .uk-accordion-content ul {
  list-style-type: none;
  padding: 0;
}
#content .leistungen .uk-accordion .uk-accordion-content ul li {
  margin-bottom: 3px;
}

#content .leistungen .uk-accordion .uk-accordion-content ul li a {
  font-size: 20px;
  line-height: 24px;
  color: #4b4b4b;
  display: block;
  text-transform: uppercase;
  padding: 10px 15px;
  text-decoration: none;
}
#content .leistung h1 {
  margin-bottom: 10px;
  font-family: "D-DIN";
  color: #00376e;
  font-size: 24px;
  line-height: 36px;
  text-align: left;
  font-weight: 900;
}
#content .leistung h1 span {
  text-transform: uppercase;
}
#content .leistung h2 {
  color: #000000;
  font-size: 24px;
  line-height: 30px;
}
#content .leistung a {
  text-decoration: none;
}

#content .leistung .gallery {
  background-color: #fbfbfb;
}
#content .leistung .gallery .carousel {
  margin-bottom: 10px;
}
#content .leistung .gallery .item.video .playbutton {
  display: inline-block;
  //position: absolute;
  //left: 40%;
  //top: 30%;
}
#content .leistung .gallery .item.video .playbutton svg {
  transform: scale(0.8);
  filter: drop-shadow(5px 5px 7px rgba(0, 0, 0, 0.3));
}
#content .leistung .gallery .item.video .playbutton svg ellipse {
  fill: white;
  fill-opacity: 0.8;
}
#content .leistung .gallery .item.video .playbutton svg path {
  fill: black;
  fill-opacity: 0.5;
}
#content .leistung .gallery .item.video:hover .playbutton svg ellipse {
  fill-opacity: 1;
}

#content .leistung .partnerDescritption {
  margin: auto;
  font-family: "D-DIN";
  color: #4f4f4f;
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
}
#content .leistung .description .item-links {
  padding: 50px 0px 100px;
  text-align: center !important;
  font-family: "D-DIN";
  color: #008cc8;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
}
#content .leistung .description .item-links a {
  font-family: "D-DIN";
  color: #008cc8;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
}
#content .leistung .description .item-links a:before {
  height: 24px;
  width: 24px;
  display: inline-block;
  background-color: #fbfbfb;
  color: #008cc8;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  content: "+";
}
#content .leistung .description .item-links a.minus:before {
  content: "-";
}
#content .leistung .service {
  padding: 0px;
  padding: 60px 0px;
  text-align: center;
}
#content .leistung .service .row {
  margin: 0px;
}
#content .leistung .service a {
  color: #fff;
}
#content .leistung .service hr {
  border-top: solid 2px #bdbdbd;
}
#content .leistung .service hr:last-child {
  display: none;
}

#content .leistung .service .left-rectangle-container {
  background: #008cc8;
  padding: 30px;
  margin: 0 35px 0 65px;
}
#content .leistung .service .left-rectangle-container .content .logo img {
  width: 50%;
  height: auto;
}
#content .leistung .service .left-rectangle-container .content h2 {
  display: block;
  font-family: "D-DIN";
  color: white;
  font-size: 18px;
  font-style: normal;
  line-height: 24px;
  text-align: center;
  margin-top: 12px;
}
#content .leistung .service .right-rectangle-container {
  margin: 0 auto;
}
#content .leistung .service .right-rectangle-container .content {
  background: #fff;
  margin: 20px 0px;
}
#content .leistung .service .right-rectangle-container .content h1 {
  display: inline-block;
  width: 100%;
  max-width: 100%;
  margin: 0px;
  padding: 10px 0px;
  text-align: left;
  font-family: "D-DIN";
  color: #00376e;
  font-size: 36px;
  font-weight: normal;
  font-style: normal;
  font-weight: 500;
  line-height: 42px;
}
#content .leistung .service .right-rectangle-container .content h2 {
  display: inline-block;
  width: 100%;
  max-width: 100%;
  margin: 0px;
  padding: 10px 0px;
  text-align: left;
  font-family: "D-DIN";
  color: #00376e;
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
}
#content .leistung .service .right-rectangle-container .content a {
  float: left;
}
#content .leistung .service .right-rectangle-container .content a button {
  background: #bdb3cc;
  border-radius: 7px;
  line-height: 24px;
  font-weight: 500;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.02em;
  border: none;
  font-family: "D-DIN";
  color: #fff;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
}
#content .leistung .service .right-rectangle-container .content p.bodytext {
  text-align: left;
}
#content .leistung .service .right-rectangle-container .content h5 {
  text-align: left;
}
#content
  .leistung
  .service
  .right-rectangle-container
  .content
  .more-info.container {
  width: auto;
  background-color: #f6f6f6;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.11);
  margin: 0 -15vw;
  max-width: 95vw;
  min-width: 45vw;
  color: #4f4f4f;
  text-align: center;
  border-radius: 6px;
  top: 30px;
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}
#content
  .leistung
  .service
  .right-rectangle-container
  .content
  .more-info.container:after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -25px;
  border-width: 25px;
  border-style: solid;
  border-color: transparent transparent #f6f6f6 transparent;
}
#content
  .leistung
  .service
  .right-rectangle-container
  .content
  .more-info.container
  h2 {
  font-weight: bold;
  line-height: 24px;
  font-family: Roboto;
  color: #00376e;
  font-size: 18px;
  font-style: normal;
}
#content
  .leistung
  .service
  .right-rectangle-container
  .content
  .more-info.container
  p.bodytext {
  line-height: 24px;
  font-family: Roboto;
  color: #4f4f4f;
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
}
#content .leistung .address {
  background: #ffedd7;
  padding: 0;
}

#content .leistung .address .content {
  padding: 15px 35px 0;
}

#content .leistung .address .content .address-inner {
  margin-bottom: 0;
  font-family: Roboto;
  word-wrap: break-word;
}
#content .leistung .address.container div .vcenter {
  height: 100%;
}

#content .leistung .address .vcenter > div {
  max-width: 100vw;
}

#content .leistung .address.container div #map {
  height: 100%;
  width: 100%;
}
#content .leistung .address.container div #map img {
  height: 100%;
  object-fit: cover;
  float: right;
}
#content .leistung div a.google {
  background: #008cc8;
  border-radius: 7px;
  padding: 10px 20px;
  display: inline-block;
  margin: 50px 0px;
  font-family: "D-DIN";
  color: #ffffff;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
}
#content .leistung .address.container .content {
  padding: 0px 10px;
  text-align: left;
}
#content .leistung .address.container .content h2 {
  font-family: "D-DIN";
  color: #00376e;
  font-size: 42px;
  font-style: normal;
  font-weight: normal;
}
#content .leistung .address.container .content .address-inner {
  font-family: "D-DIN";
  color: #333333;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  word-wrap: break-word;
}
#content .leistung .l-icon {
  display: inline-block;
  background-color: #999;
  height: 50px;
  width: 50px;
  border: 2px solid #fff;
  border-radius: 10px;
  text-align: center;
  line-height: 50px;
  font-size: 21px;
  color: #fff;
  vertical-align: top;
  margin-bottom: 5px;
  text-transform: uppercase;
  font-family: "Oswald", sans-serif;
}
#content .leistung .l-icon.icon-free {
  background-color: #00bef0;
  line-height: 20px;
  font-size: 21px;
}
#content .leistung .l-icon.icon-free .sub {
  line-height: 12px;
  font-size: 10px;
}
#content .leistung .l-icon.icon-half {
  background-color: #00a036;
}
#content .leistung .l-icon.icon-half .first {
  display: block;
  line-height: 11px;
  font-size: 11px;
  margin-top: 10px;
}
#content .leistung .l-icon.icon-half .second {
  display: block;
  font-size: 16px;
  line-height: 12px;
  margin-top: 5px;
}
#content .leistung .l-icon.icon-special {
  background-color: #db6c18;
  font-size: 12px;
}
#content .leistung .l-icon.icon-nlspecial {
  background-color: #db6c18;
}
#content .leistung .l-icon.icon-nlspecial .first {
  display: block;
  line-height: 16px;
  font-size: 16px;
  margin-top: 7px;
}
#content .leistung .l-icon.icon-nlspecial .second {
  display: block;
  font-size: 12px;
  line-height: 12px;
  margin-top: 5px;
}
#content .leistung h2.category {
  line-height: 24px;
  font-family: "D-DIN";
  color: #00376e;
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
}
#content .leistung .undermap {
  background-color: #c7c7c7;
  padding: 15px 0;
  text-align: center;
  color: #333;
}
#content .service-list .service-list-header {
  font-family: "D-DIN";
  color: #00376e;
  font-size: 42px;
  font-style: normal;
  font-weight: normal;
  line-height: 60px;
}
#content .service-list .service-list-filter {
  padding: 10px 0px;
}
#content .service-list .service-list-filter .uk-first-column {
  padding-left: 0;
}

#content .service-list .uk-container {
  padding: 0 25px;
}
#content .service-list .uk-container .uk-grid {
  margin-left: 0;
}
#content .service-list-item-color {
  display: inline-block;
  border-radius: 50%;
  width: 0.75em;
  height: 0.75em;
}
#content .service-list .service-list-filter select {
  border: 0.5px solid #333333;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 5px 25px;
  text-align: left;
}
#content .service-list .service-list-filter select option {
  text-align: left;
  font-family: "D-DIN";
  color: #000000;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
}
#content .service-list .service-list-filter .container {
  font-family: "D-DIN";
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* Create a custom checkbox */
}
#content .service-list .service-list-filter .filter-container {
  font-family: "D-DIN";
  display: inline-block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* Create a custom checkbox */
}

#content .service-list .service-list-filter .container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
#content .service-list .service-list-filter .filter-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
#content
  .service-list
  .service-list-filter
  .filter-container
  input:checked
  ~ .checkmark:after {
  display: block;
  left: 5px;
  top: 5px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #008cc8;
}
#content .service-list .service-list-filter .filter-container .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #fff;
  border: 0.5px solid #333333;
  box-sizing: border-box;
  border-radius: 5px;
  margin-top: 5px;
}
#content .service-list .service-list-filter .filter-container .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
#content
  .service-list
  .service-list-filter
  .filter-container:hover
  input
  ~ .checkmark {
  background-color: #fff;
}
#content .service-list .service-list-item,
#content .service-list .panel-title {
  font-family: "D-DIN";
  color: #00376e;
  font-size: 36px;
  font-style: normal;
  font-weight: normal;
  line-height: 42px;
  padding: 20px 0px;
}
#content .service-list .uk-accordion-title,
#content .service-list .service-list-item h4,
#content .service-list .uk-accordion-title h4 {
  text-align: left;
  font-family: "D-DIN";
  color: #00376e;
  font-size: 30px;
  font-style: normal;
  font-weight: normal;
  line-height: 36px;
  text-decoration: none;
}

#content .service-list .service-list-item a {
  color: #008cc8;
}
#content .service-list .service-list-item a:hover,
#content .service-list .uk-accordion-title:hover,
#content .service-list .service-list-item h4:hover,
#content .service-list .uk-accordion-title h4:hover {
  color: #00376e;
}
#content .service-list .service-list-item a:hover:after,
#content .service-list .panel-title a:hover:after,
#content .service-list .service-list-item h4:hover:after,
#content .service-list .panel-title h4:hover:after {
  margin-left: 12px;
  margin-top: 12px;
  font-weight: bold;
  content: "";
  border: solid #00376e;
  border-width: 0 4px 4px 0;
  display: inline-block;
  padding: 6px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
#content .accordion .panel {
  border: 0;
  border-radius: 0;
  box-shadow: none;
  margin-bottom: 3px;
}
#content .accordion .panel + .panel {
  margin-top: 0;
  margin-bottom: 3px;
}
#content .accordion .panel .panel-heading {
  width: 100%;
  padding: 0;
  border-radius: 0;
}
#content .accordion .panel .panel-heading .panel-title {
  float: left;
  width: 100%;
}
#content .accordion .panel .panel-body {
  background-color: #86dbf0;
  border: none;
  padding: 20px 15px;
}
#content .accordion .panel .panel-body p {
  margin-bottom: 15px;
}
#content .accordion .panel .panel-body p:last-child {
  margin-bottom: 0;
}
#content #news-slider-content .news-block-content {
  width: 90%;
}
#newsslider .new {
  color: #f5aa41;
  font-weight: 900;
  font-size: 24px;
  line-height: 36px;
}
#newsslider .title {
  font-weight: 900;
  color: #00376e;
  font-size: 24px;
  line-height: 36px;
}
#newsslider .content {
  line-height: 24px;
  font-family: "Roboto";
  color: #4f4f4f;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
}
#newsslider img {
  max-width: 60vw;
}
#newsslider li:not(.uk-active) img,
.gallery li:not(.uk-active) img {
  filter: grayscale(100%);
}

#newsslider .control-left {
  left: 50px;
}
#newsslider .control-right {
  right: 50px;
}

#content #news-slider-content .carousel-controls a.left,
#content #news-slider-content .carousel-controls a.right {
  width: 25px;
}
#content #news-slider-content .carousel-controls a.left {
  left: -40px;
}
#content #news-slider-content .carousel-controls a.right {
  right: -40px;
}
@media (max-width: 767px) {
  #content #map_container #map {
    height: 350px !important;
  }
}
#content #map_container {
  position: relative;
}
#content #map_options_toggle {
  display: none;
  font-size: 13px;
  position: absolute;
  top: 8px;
  right: 8px;
  width: 80px;
  color: #fff;
  background-color: #000;
  opacity: 0.8;
  line-height: 30px;
  border-top-left-radius: 5px;
  text-align: center;
}
@media (max-width: 767px) {
  #content #map_options_toggle {
    display: none;
  }
}
#content #map_options_toggle a {
  color: #fff;
  text-decoration: none;
}
#content #map_sidebar {
  position: relative;
  background-color: #fbfbfb;
  font-family: "D-DIN";
  color: #333333;
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  color: #fff;
  padding: 15px 10px;
}
@media (max-width: 767px) {
  #content #map_sidebar {
    position: relative;
    top: 0;
    right: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-top: 10px;
  }
}
#content #map_sidebar h2 {
  font-family: "D-DIN";
  color: #828282;
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  text-align: left;
  line-height: 24px;
}
#content #map_sidebar a {
  display: block;
  color: #fff;
  text-decoration: none;
  padding: 3px 0;
  font-family: "D-DIN";
  color: #008cc8;
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  font-size: 17px;
}
#content #map_sidebar a span {
  margin-right: 5px;
}
#content .kontaktformular,
#content .ticketbestellung,
#content .newsletter {
  font-size: 16px;
}
#content .kontaktformular h1,
#content .ticketbestellung h1,
#content .newsletter h1 {
  /*			font-family: 'dinboldwebfont';*/
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  color: #000;
  padding: 5px 10px;
  margin-top: 0;
}
#content .kontaktformular h2,
#content .ticketbestellung h2,
#content .newsletter h2 {
  font-size: 18px;
  font-weight: bold;
  color: #000;
  margin-bottom: 0;
}
#content .kontaktformular h2 a,
#content .ticketbestellung h2 a,
#content .newsletter h2 a {
  color: #000;
  text-decoration: underline;
}
#content .kontaktformular label,
#content .ticketbestellung label,
#content .newsletter label {
  font-weight: normal;
}
#content .logos .logo {
  text-align: center;
  margin-bottom: 15px;
}
#content .logos .logo img {
  width: unset;
  height: unset;
  max-width: 100%;
}
#content .searchResults .searchResultItem {
  margin-bottom: 20px;
}
#content .searchResults .searchResultItem h3 {
  font-size: 18px;
}
#content .searchResults .searchResultItemDate {
  font-size: 12px;
  margin-top: 5px;
  opacity: 0.3;
}
#content .searchResults .pagination > li > a,
#content .searchResults .pagination > li > span {
  text-decoration: none;
  border-color: #7f7f7f;
}
#content .searchResults .pagination > .active > a,
#content .searchResults .pagination > .active > span,
#content .searchResults .pagination > .active > a:hover,
#content .searchResults .pagination > .active > span:hover,
#content .searchResults .pagination > .active > a:focus,
#content .searchResults .pagination > .active > span:focus {
  background-color: #7f7f7f;
  color: #fff;
  border-color: #7f7f7f;
}

#content .profile-container .profile-left-container a {
  font-family: "D-DIN";
  color: #00376e;
  margin-bottom: 15px;
  display: block;
  font-size: 30px;
  line-height: 42px;
  text-decoration: none;
  padding: 7px;
  position: relative;
}
#content .profile-container .profile-left-container a:after {
  margin-top: 15px;
  font-weight: bold;
  content: "";
  border: solid #008cc8;
  border-width: 0 4px 4px 0;
  display: inline-block;
  padding: 6px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  position: absolute;
  right: 20px;
}
#content .profile-container .profile-left-container a:hover {
  color: #f5aa41;
}
#content .profile-container .profile-left-container a.active {
  background: #feefdd;
}
#content .profile-container .profile-right-container {
  font-size: 20px;
}
#content .profile-container .profile-right-container fieldset,
#content .profile-container .profile-right-container legend {
  border: 0;
  margin-top: 0;
}
#content
  .profile-container
  .profile-right-container
  .femanager_interests
  .col-sm-10 {
  width: 40%;
}
#content
  .profile-container
  .profile-right-container
  .femanager_interests
  .checkbox {
  float: left;
  width: 50%;
}
#content
  .profile-container
  .profile-right-container
  .femanager_agegroup
  .radio {
  float: left;
  margin-right: 20px;
  width: auto;
}
#content .profile-container .edit-password-link {
  font-size: 24px;
  text-decoration: none;
}
#content .profile-container .delete-account-link {
  text-decoration: none;
}
#content .profile-container .profile-right-container iframe {
  width: 100%;
  height: 700px;
}
#content .profile-container .profile-right-container .item-inner {
  background: #f7f7f7;
  padding: 30px 10px;

  .item-info .title {
    font-family: "D-DIN";
    color: #008cc8;
    font-size: 24px;
    font-style: normal;
    font-weight: normal;
    font-weight: bold;
    margin-bottom: 3px;
    line-height: 29px;
    letter-spacing: -0.02em;
  }
  .item-info .city {
    font-family: "D-DIN";
    color: #008cc8;
    font-size: 18px;
    font-style: normal;
    font-weight: normal;
    line-height: 24px;
  }

  a {
    text-decoration: none;
  }
}
#content .profile-container .profile-right-container .item-image-inner {
  max-width: 345px;
  position: relative;
}
#content
  .profile-container
  .profile-right-container
  .item
  .bookmark-remove-overlay {
  display: none;
  position: absolute;
  bottom: 0;
  right: 0;
  text-align: center;
  background: rgba(255, 0, 0, 0.5);
  padding: 5px 10px;
  color: white;
  font-size: 12px;
}
#content
  .profile-container
  .profile-right-container
  .item:hover
  .bookmark-remove-overlay {
  display: block;
}

#content .profile-container .bookmark-notice,
#content .profile-container .mycard-notice {
  margin-bottom: 2rem;
}

.startseite #content h1 {
  font-size: 26px;
  line-height: 30px;
  color: #00376e;
  font-weight: bold;
  margin-bottom: 40px;
}

@media screen and (max-width: 768px) {
  #content
    .profile-container
    .profile-right-container
    .femanager_interests
    .col-sm-10 {
    width: 100%;
  }
}
@media screen and (max-width: 960px) {
  #content .profile-container .profile-left-container a:after {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
}

.mloc {
  cursor: pointer;
}
.item-links {
  margin-top: 1em;
}

#partnerlogos {
  background-color: #fff;
  padding: 10px 30px;
}
#partnerlogos .row div.headline {
  color: #555;
  border-bottom: 1px solid #444;
  margin-top: 25px;
  text-align: left;
  padding-left: 0;
}
#partnerlogos .row:first-child .headline {
  margin-top: 0;
}
#partnerlogos img {
  max-width: 100%;
}
#partnerlogos .row div {
  margin-bottom: 15px;
  text-align: center;
}
.btn-wnr {
  /*  font-family: 'dinboldwebfont';*/
  background-color: #7f7f7f;
  font-weight: bold;
  font-size: 20px;
  text-transform: uppercase;
  color: #fff;
  border-radius: 0;
  padding: 0 30px;
}
.btn-wnr:hover {
  color: #fff;
}
.citko-directory_eintrag {
  margin-bottom: 30px;
}
.citko-directory_all h3 {
  display: block;
  margin-bottom: 0.3em;
  font-family: "Oswald", sans-serif;
  font-size: 18px;
}
.citko-li {
  background: #ffedd7;
  padding: 10px;
  font-size: 16px;
  font-family: "D-DIN";
  color: #4f4f4f;
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  border-left: 4px solid #fff;
  border-right: 8px solid #fff;
}
ul#searchlist {
  list-style-type: none;
  padding: 0;
  margin-top: 2px;
}
ul#searchlist li {
  margin: 0;
}
ul#searchlist li a {
  text-transform: uppercase;
  padding: 3px 10px;
  display: block;
  color: #4f4f4f;
  border-bottom: 2px solid #fff;
}
ul#searchlist li:last-child a {
  border-bottom: none;
}
@media (max-width: 1199px) {
  #content .text-teaser h2 {
    font-size: 18px;
    line-height: 130%;
    margin-bottom: 10px;
  }
  #content .text-teaser p {
    font-size: 14px;
    line-height: 130%;
  }
  #content .text-teaser .morelink {
    font-size: 14px;
    line-height: 130%;
  }
}
@media (max-width: 991px) {
  #content .text-teaser h2 {
    font-size: 20px;
    line-height: 150%;
    margin-bottom: 15px;
  }
  #content .text-teaser p {
    font-size: 17px;
    line-height: 150%;
  }
  #content .text-teaser .morelink {
    font-size: 17px;
    line-height: 150%;
  }
}
@media (min-width: 1230px) {
  #content .text-teaser {
    height: 240px;
  }
  #content .text-teaser h2 {
    font-size: 20px;
  }
  #content .text-teaser p {
    font-size: 17px;
  }
}
@media (max-width: 992px) {
  #content {
    margin-bottom: 50px;
  }
}
.fancybox-nav {
  top: 33%;
  height: 33%;
}
.social-links {
  padding: 0px 15px;
  margin-top: 0px;
}
.social-links .content {
  display: block;
  width: 320px;
  margin: auto;
  text-align: center;
}
.social-links .content p {
  font-family: "D-DIN";
  color: #4f4f4f;
  font-size: 36px;
  font-style: normal;
  font-weight: normal;
}
.social-links .content a {
  display: inline;
  margin-right: 20px;
}
.social-links .content a:last-child {
  margin-right: 0px;
}
.newsletter,
.social-links,
.projects {
  padding: 0px 10px;
}
.infohotline {
  padding: 0px 10px;
  margin-top: 100px;
}
.infohotline .title {
  text-align: center;
  font-family: "D-DIN";
  color: #4f4f4f;
  font-size: 36px;
  font-style: normal;
  font-weight: normal;
  line-height: 42px;
}
.infohotline .price {
  font-family: "D-DIN";
  color: #4f4f4f;
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
}
.infohotline .price span {
  display: inline-block;
  width: 100%;
  text-align: center;
}
.infohotline .order {
  display: inline-block;
  width: 100%;
  text-align: center;
}
.infohotline .order button {
  width: 345px;
  height: 73px;
  background: #f5aa41;
  border-radius: 7px;
  text-shadow: 0.6px 0.6px 0px rgba(0, 0, 0, 0.27);
  border-radius: 5px;
  letter-spacing: -0.02em;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 24px;
  color: #fff;
  border: 0px solid #f5aa41;
  margin: auto;
  text-align: center;
  margin: 25px 0px;
}
.infohotline .wishlist {
  display: inline-block;
  width: 100%;
  text-align: center;
  font-family: "D-DIN";
  color: #008cc8;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
}
form .form {
  width: 500px !important;
  margin: auto !important;
}

#content .fourcol-container h2,
#content .news h2 {
  font-size: 24px;
  line-height: 36px;
  color: #00376e;
}
#content .fourcol-container img {
  width: 100%;
}

#content .news a {
  text-decoration: none;
}
#content .news .headline {
  font-family: Roboto;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #008cc8;
}

#content .news .teaser {
  font-family: "D-DIN";
  font-weight: normal;
  font-size: 18px;
  line-height: 1.3;
}

.d-inline-block {
  display: inline-block;
}
#content .news img {
  width: 29px;
  height: 34px;
}
#content .news.news-single img {
  height: auto;
  width: auto;
}

#content .news time {
  font-family: Roboto;
  color: #4f4f4f;
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  position: relative;
  top: 2px;
}

.gallery .control-left,
.gallery .control-right {
  background: #ffffff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.gallery .control-left img,
.gallery .control-right img {
  margin: 35% 8px 35%;
}

.category-selection .category-item a {
  text-decoration: none !important;
}
.category-selection .category-item a .category-description p.bodytext {
  margin-top: 10px;
}
.category-selection .category-item a.nodecoration {
  text-decoration: none !important;
}

#content
  .fourcol-container.category-selection
  .category-item
  a
  .csc-firstHeader {
  color: #00376e;
}

.fourcol-container .teaser {
  padding-bottom: 35px;
  font-family: "Roboto";
  color: #4f4f4f;
  font-size: 16px;
  line-height: 24px;
  font-style: normal;
  font-weight: normal;
}
.fourcol-container h1 {
  margin: 0px auto;
  max-width: 331px;
  width: 100%;
  padding: 10px;
  text-align: center;
  border-radius: 5px 5px 0px 0px;
  font-family: "D-DIN";
  color: #fff !important;
  font-size: 24px !important;
  line-height: 24px !important;
  font-style: normal;
  font-weight: normal;
}

#content .fourcol-container a .csc-firstHeader {
  text-align: center;
  color: #fff;
  width: 100%;
  padding: 10px;
  font-size: 24px;
  line-height: 24px;
}

.fourcol-container .csc-header.csc-header-n1 {
  text-align: center;
}

#content
  .csc-frame.csc-frame-default
  .fourcol-container
  .csc-header.csc-header-n1
  h1.csc-firstHeader
  a {
  font-size: 24px;
  line-height: 24px;
}
.fourcol-container .row .csc-frame-default {
  margin: 0px;
}
.flex-row {
  display: flex;
  flex-wrap: wrap;
}
.flex-row > [class*="col-"] {
  display: flex;
  flex-direction: column;
}
.flex-row.row:after,
.flex-row.row:before {
  display: flex;
}
h2 {
  font-family: "D-DIN";
  color: #00376e;
  font-size: 42px;
  font-style: normal;
  font-weight: 900;
  line-height: 60px;
}
/* News */
.news-list-view-standard {
  padding: 0px 15px;
}
.news-list-view-standard ul {
  list-style-type: none;
  padding: 0px;
  margin: 0;
  font-size: 1rem;
}
.news-list-view-standard ul li {
  padding: 20px 0px;
  border-bottom: 1px solid #e0e0e0;
}
.news-list-view-standard ul li:last-child {
  border-bottom: none;
}
.news-list-view-standard ul li a {
  color: #666;
}

.news-list-view-standard .btn {
  margin-top: 20px;
}
.news-list-view-standard .btn.btn-primary {
  background-color: #008cc8;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
}
.news-list-view-standard .btn.btn-primary a {
  font-family: "D-DIN";
  color: #fff;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
}
.news-list-view-standard {
  padding: 0px 15px;
}
.news-list-view-standard h3 a {
  font-family: "D-DIN";
  color: #008cc8;
  font-size: 36px;
  font-style: normal;
  font-weight: normal;
  line-height: 42px;
}
.news-list-view-standard ._lead {
  width: 80%;
}
.news-list-view-standard ._lead .bodytext {
  float: left;
  width: 100%;
  font-family: "D-DIN";
  color: #4f4f4f;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
}
.news-single .news-text-wrap .bodytext {
  text-align: center !important;
}

.news-list-view-standard ._lead a {
  font-family: "D-DIN";
  color: #008cc8;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
}
.csc-firstHeader h1 {
  font-family: "D-DIN";
  color: #00376e;
  font-size: 42px;
  font-style: normal;
  font-weight: normal;
  line-height: 60px;
}
.vcenter {
  display: flex;
  align-items: center;
}
.no-news-found {
  color: #7f7f7f;
}
.news .extra {
  border-top: 1px solid #eee;
  padding-top: 15px;
  margin-top: 15px;
  text-align: right;
  color: #7f7f7f;
  margin-bottom: 15px;
}
.news .lead {
  color: #666;
}
.news .caption {
  font-size: 14px;
  color: #7f7f7f;
}
.news .news-list-item {
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 2px solid #eee;
}
.news .news-list-item:last-child {
  border-bottom: none;
}
.news .news-list-item h3 {
  font-size: 20px;
}
.news .news-list-item h3 a {
  text-decoration: none;
}
.news .news-list-item h3 a .date {
  color: #7f7f7f;
  float: right;
}
.news .news-list-item h3 a .date time {
  font-size: 16px;
}
#content .news-list-item h3 a {
  text-decoration: none;
}
.container-page-181 .news-list-view-standard {
  margin: 0px;
}
.form-horizontal .form-group {
  margin: 0px;
  padding: 0px;
  padding-right: 20px;
  padding-bottom: 10px;
}
.container-page-36 #content .csc-frame-default .container-fluid {
  padding: 0px;
}
.container-page-36 #content .csc-frame-default .csc-header h1.csc-firstHeader {
  text-align: left;
  font-family: "D-DIN";
  color: #00376e;
  font-size: 42px;
  font-style: normal;
  font-weight: normal;
}
.container-page-36 #content .csc-frame-default p.bodytext {
  font-family: "D-DIN";
  color: #00376e;
  font-size: 36px;
  font-style: normal;
  font-weight: normal;
}
.container-page-36
  #content
  .csc-frame-default
  form
  .form-horizontal
  .form-group {
  margin: 0px;
}
.csc-frame-indent {
  margin: 0;
}

@media screen and (max-width: 450px) {
  #navigation .container {
    padding: 0;
  }
  #navigation .container .navbar .navbar-header .navbar-brand {
    height: 58px;
  }
  #navigation .container .navbar .navbar-header .navbar-toggle {
    padding: 11px;
  }
  #navigation .container .navbar .navbar-header > a > img {
    width: 130px;
  }

  .infohotline .order button {
    width: 100%;
  }
}

@media screen and (max-width: 640px) {
  html,
  body {
    font-size: 16px;
    line-height: 24px;
  }

  #content .leistung h1,
  #content h1,
  #content h2,
  #content .leistung .category,
  #header .csc-frame-default .header-container.page-144 .content h1,
  #header .csc-frame-default .header-container.page-145 .content h1,
  #header .csc-frame-default .header-container.page-146 .content h1,
  #header .csc-frame-default .header-container.page-229 .content h1,
  #header .csc-frame-default .header-container.page-148 .content h1,
  #header .csc-frame-default .header-container.page-152 .content h1,
  #content .service-list .service-list-header {
    font-size: 24px;
    line-height: 36px;
  }

  #content .csc-frame-default .csc-textpic-intext-left .csc-textpic-imagewrap,
  #content .csc-frame-default .csc-textpic-intext-right .csc-textpic-imagewrap {
    float: left;
    max-width: 100%;
    width: 100%;
  }

  div.csc-textpic-intext-right .csc-textpic-imagewrap,
  div.csc-textpic-intext-right-nowrap .csc-textpic-imagewrap {
    margin-left: 0;
  }

  #content .csc-frame-default .csc-textpic-intext-left .csc-textpic-text,
  #content .csc-frame-default .csc-textpic-intext-right .csc-textpic-text {
    width: auto;
  }

  #content .service-list .service-list-item a,
  #content .service-list .service-list-item h4,
  #content .service-list .uk-accordion-title h4 {
    font-size: 18px;
    font-style: normal;
    font-weight: normal;
    line-height: 24px;
    text-decoration: none;
    float: left;
  }

  #content .service-list .service-list-item a:hover::after,
  #content .service-list .service-list-item h4:hover::after {
    margin-top: 0;
    font-weight: bold;
    border-width: 0 4px 4px 0;
    padding: 4px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }

  #content .news .teaser {
    font-size: 16px;
    line-height: 24px;
  }
}

@media screen and (max-width: 960px) {
  #newsslider .uk-text-center {
    text-align: left !important;
  }
  #newsslider .uk-slider-items {
    transform: none !important;
    margin-bottom: 1.2rem;
  }
}
@media screen and (min-width: 640px) {
  .startseite #content h1 {
    font-size: 45px;
    line-height: 54px;
  }

  #newsslider .new,
  #newsslider .title {
    font-size: 42px;
    line-height: 60px;
  }

  #newsslider .content {
    font-size: 18px;
  }

  #newsslider img {
    max-width: 460px;
  }

  #content h1,
  .header-container h1 {
    font-size: 42px;
    line-height: 56px;
  }

  #content h1 {
    color: #00376e;
  }

  .header-container p {
    font-size: 36px;
    line-height: 42px;
  }

  #content .csc-subheader {
    font-weight: 500;
    font-size: 36px;
    line-height: 42px;
    text-align: center;
  }

  .listingalternativ #content .csc-subheader {
    color: #f2f2f2;
    margin-bottom: 400px;
  }

  #content .fourcol-container h2,
  #content .news h2 {
    font-size: 42px;
    line-height: 60px;
  }

  #content .news {
    font-size: 2rem;
  }
  #content .news .headline {
    font-family: "D-DIN";
    font-weight: 500;
    font-size: 24px;
    /*line-height: 42px;*/
  }

  #content .news .teaser {
    /*font-size: 24px;*/
  }

  #content .news img {
    width: 41px;
    height: 48px;
  }

  #content .news time {
    /*font-size: 36px;*/
    font-style: normal;
    font-weight: 500;
    /*line-height: 42px;*/
    top: 10px;
  }

  .fourcol-container .teaser {
    font-size: 18px;
    line-height: 24px;
  }

  #content .leistung .service .left-rectangle-container {
    background: url("/assets/img/Rectangle 6.png") no-repeat top left;
    background-size: cover;
  }

  #content .leistung h1 {
    font-size: 42px;
    line-height: 60px;
    text-align: center;
  }

  #content .leistung h2.category {
    font-size: 36px;
    line-height: 44px;
    text-align: center;
  }

  #content .leistung .partnerDescritption {
    max-width: 750px;
  }

  #content .leistung .service .left-rectangle-container {
    height: 560px;
    margin: 0;
  }

  #content .leistung .service .left-rectangle-container .content h2 {
    color: #98d6f1;
    font-family: Roboto;
    font-weight: 900;
    font-size: 42px;
    line-height: 60px;
    font-style: italic;
  }

  #content .leistung .service .content button {
    float: left;
  }

  #content .leistung .address .vcenter > div {
    max-width: 50vw;
  }
}
#presentationRating span {
  cursor: pointer;
  margin-bottom: 2rem;
}
#presentationRating .highlighted polygon {
  fill: yellow;
}

.presentationRatingContainer h5 {
  color: #00376e !important;
  font-size: 24px !important;
  text-align: center;
  font-weight: bold;
}
.presentationRatingContainer p {
  color: #00376e;
}

@media screen and (min-width: 985px) {
  .listing #content h1 {
    text-align: left;
    margin-left: 110px;
  }

  .listing #content .csc-header-n1 ~ .bodytext {
    margin-left: 110px;
  }
}

/*
    Presentation Performance
   */

.performance-container {
  margin-bottom: 110px;
  margin-top: 90px;
  position: relative;
}
.performance-container > svg {
  display: none;
}

.performance-container .item {
  font-family: Roboto;
  font-size: 16px;
  height: 110px;
  margin-bottom: 4px;
  margin-top: -10px;
  position: relative;
}
.performance-container .item svg {
  margin-top: -10px;
  height: 110px;
  width: 500px;
  filter: drop-shadow(0px 0px 10px hsla(0, 0%, 0%, 0.3));
}
.performance-container .item polygon {
  fill: #008cc8;
}
.performance-container .item:hover polygon {
  fill: #face9c;
}
.performance-container .item:first-of-type {
  height: 170px;
}
.performance-container .item:first-of-type svg {
  height: 170px;
}

.performance-container .item .content {
  background: white;
  padding: 28px 50px;
  margin: 10px 0;
  vertical-align: middle;
  display: table-cell;
  width: 100vw;
  word-break: break-word;
}
.performance-container .item:hover .content {
  background: #feefdd;
}

.performance-container .label-container {
  position: absolute;
}
.performance-container .label-container .label {
  font-family: "D-DIN";
  font-size: 26px;
  font-weight: 400;
  text-align: left;
  padding: 0;
  position: absolute;
  left: 25px;
  color: white;
  z-index: 10;
  line-height: 26px;
  height: 28px;
  white-space: normal;
  margin: auto;
  top: 0;
  bottom: 0;
}

.performance-container .item:hover .label {
  color: #00376e;
}
.performance-container .label-container .label .title {
  color: #98d6f1;
  font-weight: 900;
  font-size: 30px;
  line-height: 60px;
  font-style: italic;
  text-align: left;
}
.performance-container .item p {
  margin: 0 !important;
}
.performance-container .item:nth-of-type(1) .content {
  padding-left: 535px;
  height: 140px;
}
.performance-container .item:nth-of-type(2) .content {
  padding-left: 495px;
}
.performance-container .item:nth-of-type(3) .content {
  padding-left: 475px;
}
.performance-container .item:nth-of-type(4) .content {
  padding-left: 455px;
}
.performance-container .item:nth-of-type(5) .content {
  padding-left: 435px;
}

@media screen and (max-width: 1020px) {
  .performance-container .item {
    height: auto !important;
    margin-bottom: 30px !important;
  }
  .performance-container .item .content {
    height: auto !important;
    padding-left: 20px !important;
  }
  .performance-container .label-container {
    position: relative;
  }
}

/*
    Contact form
   */

.form-group {
  margin-top: 13px;
}
.control-label {
  margin-bottom: 4px !important;
}
textarea {
  padding: 15px;
}
.powermail_fieldwrap_type_check {
  padding-bottom: 30px;
  margin-left: 25%;
}
.powermail_fieldwrap_type_check label {
  padding-left: 0;
}
.powermail_fieldwrap_type_check input {
  position: relative;
  margin-right: 7px !important;
}
.powermail_fieldwrap_type_submit input.btn {
  border: 0 !important;
  color: white !important;
  padding: 7px 25px;
}

@media screen and (max-width: 768px) {
  .control-label {
    margin-left: 15px !important;
  }
  .powermail_fieldwrap_type_check {
    margin-left: 0;
  }
}

/*
    Login form
   */
#login-box {
  max-width: 500px;
}
#login-box label {
  margin-bottom: 10px;
}

#login-box .uk-button {
  border-radius: 7px;
  font-size: 16px;
  padding: 10px 15px;
  text-transform: none;
  line-height: 14px;

  &.google {
    padding: 4px;
    width: auto;
  }
}

#login-box a {
  text-decoration: none;
}

/*
    Search form / results
   */

.tx-kesearch-pi1 {
  max-width: 1000px;
}
.tx-kesearch-pi1 a {
  color: #008cc8 !important;
}

.tx-kesearch-pi1 .form-control {
  border: 1px solid #ccc !important;
  border-radius: 4px !important;
}

.tx-kesearch-pi1 .btn-primary {
  border-radius: 7px;
  font-size: 16px;
  padding: 10px 15px;
  text-transform: none;
  line-height: 14px;
  min-width: auto;
}
.tx-kesearch-pi1 #kesearch_num_results {
  font-size: 16px;
  margin-bottom: 10px;
}
.tx-kesearch-pi1 .hit {
  color: #008cc8 !important;
}
.tx-kesearch-pi1 .result-title {
  margin-bottom: 5px;
}
.tx-kesearch-pi1 .result-title a {
  text-decoration: none !important;
}
.tx-kesearch-pi1 .result-title a:hover {
  color: #f5aa41 !important;
}

#content .faq-container .uk-accordion-title {
  padding-right: 20px;
}

.opening-hours-container {
  margin-bottom: 30px;
}
.opening-hours-container h2 {
  color: #00376e !important;
  font-size: 40px !important;
  text-align: center;
}
.opening-hours-container .box .inner {
  background: #f5aa41;
  color: #00376e !important;
  padding: 30px;
  word-wrap: break-word;
}

.opening-hours-container .box:last-child {
  flex: 1;
  min-width: 1px;
}
.opening-hours-container .uk-grid {
  margin-left: -10px;
}
.opening-hours-container .uk-grid > * {
  padding-left: 10px;
}
.opening-hours-container .uk-grid > .uk-grid-margin {
  margin-top: 10px;
}

.login-breacrumb {
  margin: 50px 0 20px 0;
}
.login-breacrumb a {
  margin-right: 30px;
  text-decoration: none !important;
}
.login-breacrumb a:hover {
  text-decoration: underline !important;
}

.sociallogin-container .uk-button {
  color: white;
  float: left;
  margin: 0 5px;
  width: 45%;
}
.sociallogin-container .uk-button.facebook {
  background: #3b5998;
}
.sociallogin-container .uk-button.google {
  background: #dd4b39;
}
.sociallogin-container .uk-button span {
  margin-right: 7px;
}
.sociallogin-container .uk-button path {
  color: white;
}

.filter-form {
  .uk-form-label {
    width: 75px;
  }
  .uk-form-controls {
    margin-left: 90px;
  }
}

.img-responsive {
  width: 100%;
  height: auto;
}

@media (max-width: 959px) {
  .filter-form .uk-form-label {
    margin-top: 7px;
    float: left;
  }
}

.bildverhaeltnis-favorit {
}

#navigation {
  .uk-logo img {
    height: 40px;
  }
  .uk-navbar-nav > li > a,
  .uk-navbar-item,
  .uk-navbar-toggle {
    min-height: 50px;
  }
}
